import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { InvoicingForm } from "../components/invoicingForm";

export const InvoicingManager = () => {
  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Faturamentos", href: "" },
    { description: "Lista de Faturamentos", href: "/invoicing" },
    {
      description: "Cadastrar Faturas",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600" mb="1em">
        Cadastrar Faturas
      </HLarge2xl>

      <InvoicingForm />
    </Wrapper>
  );
};
