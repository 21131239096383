import { recordApi } from "../../../../main/services/RecordService";
import { IUndoWriteOffForm } from "../interfaces/undo-manual-write-off.interface";

export const undoWriteOffReceivable = async (
  data: IUndoWriteOffForm,
  carUuid: string
) => {
  try {
    const response = await recordApi.patch(
      `/processingcar/undoWriteOff/${carUuid}`,
      data
    );
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
