/* eslint-disable react/no-unstable-nested-components */
import { Box, Flex } from "@chakra-ui/react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useEffect, useMemo, useState } from "react";
import { MODULES } from "../../../../main/common/constants/modules";
import { format_CPF_CNPJ } from "../../../../main/common/utils/format_CPF_CNPJ";
import { Backdrop } from "../../../../main/components/Backdrop";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import { fuzzySort } from "../../../../main/components/DataTableServer/Sort/functions";
import {
  TableFilteredParams,
  TableSortingParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { HLarge2xl } from "../../../../main/components/Tipography/HeadingLarge";
import { Wrapper } from "../../../../main/components/Wrapper";
import {
  enumTypeOfPayment,
  IContractsList,
} from "../../../../types/main/contract";
import { IPaginateResponse } from "../../../../types/main/pagination";
import { IResponse } from "../../../../types/main/response";
import { PopoverContract } from "../components/PopoverContract";
import { getAllContractsList } from "../service/ContractService";

export const ContractList: React.FC = () => {
  const [contracts, setContracts] = useState<{
    data: IContractsList[];
    pageCount: number;
  }>({
    data: [],
    pageCount: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const handleContractList = async (
    pagination?: PaginationState,
    filters?: TableFilteredParams,
    sorting?: TableSortingParams
  ) => {
    setIsLoading(true);
    const response = (await getAllContractsList({
      pagination: pagination?.pageSize
        ? pagination
        : { pageIndex: 0, pageSize: 10 },
      filters,
      sorting,
    })) as IResponse<IPaginateResponse<IContractsList>>;

    if (response?.type === "success" && response.body) {
      setContracts({
        data: response.body.data,
        pageCount: response.body.lastPage,
      });
    }
    setIsLoading(false);
  };

  const {
    paginationState,
    sortingState,
    columnFiltersState,
    paginationParams,
    columnFiltersParams,
    columnSortingParams,
  } = useTableServer({
    pageCount: contracts.pageCount,
  });

  const columns = useMemo<ColumnDef<IContractsList, any>[]>(
    () => [
      {
        header: "Tipo de Pagamento",
        accessorKey: "paymentType",
        cell: (info) =>
          enumTypeOfPayment[info.getValue() as keyof typeof enumTypeOfPayment],
        sortingFn: "text",
      },
      {
        header: "ID do Contrato",
        id: "idContract",
        accessorKey: "idContract",
        filterFn: "fuzzy",
      },
      {
        header: "Nº em Cartório",
        id: "registrationNumber",
        accessorKey: "registrationNumber",
      },
      {
        header: "Canal",
        id: "channelId",
        accessorKey: "channelId",
        filterFn: "fuzzy",
      },
      {
        header: "Produto",
        accessorKey: "productName",
      },
      {
        header: "Cliente",
        accessorKey: "clientName",
        sortingFn: fuzzySort,
      },
      {
        header: "Documento cliente",
        accessorKey: "document",
        cell: (info) => format_CPF_CNPJ(info.getValue()) ?? "",
        filterFn: "document",
      },
      {
        header: "Banco",
        accessorKey: "bankNumber",
        filterFn: "fuzzy",
      },
      {
        header: "Agência",
        accessorKey: "agencyNumber",
        filterFn: "fuzzy",
      },
      {
        header: "Conta corrente",
        accessorKey: "currentAccount",
        filterFn: "fuzzy",
      },
      {
        header: "Data de criação",
        accessorKey: "createdAt",
        cell: (info) => {
          const creationDate_str = new Date(info.getValue());
          return format(creationDate_str, "dd/MM/yyyy", {
            locale: ptBR,
          });
        },
        filterFn: "date",
      },
      {
        header: "Inclusão (Fat.)",
        accessorKey: "inclusionDate",
        cell: (info) => {
          const creationDate_str = new Date(info.getValue());
          return format(creationDate_str, "dd/MM/yyyy", {
            locale: ptBR,
          });
        },
      },
      {
        header: "Juros",
        accessorKey: "juros",
        cell: (info) => `${Number(info.getValue()).toFixed(3)}%`,
      },
      {
        header: "Juros mensal",
        accessorKey: "monthlyInterest",
        cell: (info) => `${Number(info.getValue()).toFixed(2)}%`,
      },
      {
        header: "Multa",
        accessorKey: "multa",
        cell: (info) => `${Number(info.getValue()).toFixed(2)}%`,
        enablePinning: true,
      },
      {
        header: (info) => (
          <PopoverContract
            refetch={handleContractList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
        id: "actions",
        cell: (info) => (
          <PopoverContract
            info={info}
            refetch={handleContractList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
      },
    ],
    []
  );

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Contratos", href: "/contractlist" },
    { description: "Gerenciamento de contratos", href: "" },
  ];

  useEffect(() => {
    handleContractList(
      paginationParams,
      columnFiltersParams,
      columnSortingParams
    );
  }, [paginationParams, columnFiltersParams, columnSortingParams]);

  return (
    <Wrapper>
      <Flex flexDir="column">
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Gerenciamento de contrato
          </HLarge2xl>
          <Box>
            <CreateNewDataButton
              module={MODULES.CONTRACTS}
              path="/managecontract"
              text="Cadastrar novo contrato"
            />
          </Box>
        </Flex>
        <TableServer
          refresh={handleContractList}
          data={contracts.data}
          defaultColumns={columns}
          isLoading={isLoading}
          paginationState={paginationState}
          columnFiltersState={columnFiltersState}
          sortingState={sortingState}
        />
      </Flex>
      {isExporting && <Backdrop />}
    </Wrapper>
  );
};
