/* eslint-disable react/no-unstable-nested-components */
import { Flex } from "@chakra-ui/react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getDateFormatted } from "../../../../main/common/utils/dateHelper";
import { BadgeTooltip } from "../../../../main/components/BadgeTooltip";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DueDateCell } from "../../../../main/components/DataTable/DueDateCell";
import { TableServer } from "../../../../main/components/DataTableServer";
import {
  getFiltersConsideringQuery,
  TableFilteredParams,
  TableSortingParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { StatusSubtitle } from "../../../../main/components/StatusSubtitle";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IBoletoList } from "../../../../types/main/boleto";
import {
  IInvoiceDataList,
  IInvoiceFilters,
} from "../../../../types/main/invoice";
import { tariffStatusLabel } from "../../../../types/main/tariff";
import { BoletosFilters } from "../components/BoletoFilter";
import { BoletoPopover } from "../components/BoletoPopover";
import { BoletoService } from "../services/BoletoService";

const StatusDescriptionCell = ({
  status,
  date,
}: {
  status: keyof typeof tariffStatusLabel;
  date: string;
}) => {
  const translatedStatus = tariffStatusLabel[status]?.description ?? "";
  const formattedDate = getDateFormatted(date);
  return (
    <BadgeTooltip
      label={`${translatedStatus} ${formattedDate}`}
      colorScheme={tariffStatusLabel[status].color ?? "gray"}
    />
  );
};

export const BoletoList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [boletos, setBoletos] = useState<{
    data: IBoletoList[];
    total: number;
  }>({
    data: [],
    total: 0,
  });

  const [query] = useSearchParams();

  const invoiceId = useMemo(() => query.get("invoiceId"), [query]);
  const tariffId = useMemo(() => query.get("tariffId"), [query]);

  const handleSearch = async (
    filters?: IInvoiceFilters,
    pagination?: PaginationState,
    columnFilters?: TableFilteredParams,
    sorting?: TableSortingParams
  ) => {
    setIsLoading(true);
    const response = await BoletoService.getAllBoletos({
      filters,
      pagination,
      columnFilters,
      sorting,
    });

    if (response.type === "success" && response.body) {
      setBoletos({
        data: response.body.data,
        total: response.body.lastPage,
      });
    }
    setIsLoading(false);
  };

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    columnFiltersState,
    paginationState,
    sortingState,
  } = useTableServer({
    pageCount: boletos.total,
  });

  const columns = useMemo<ColumnDef<IInvoiceDataList, any>[]>(
    () => [
      {
        header: "Status",
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].virtualStatus,
        cell: (info) => (
          <StatusDescriptionCell
            status={info.getValue().status}
            date={info.getValue().date}
          />
        ),
        enableSorting: false,
        minSize: 50,
        enableColumnFilter: false,
      },
      {
        header: "Nosso Numero",
        id: "nossoNumero",
        accessorKey: "nossoNumero",
      },
      {
        header: "Id da Fatura",
        id: "invoiceId",
        enableColumnFilter: false,
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].tariff?.invoice?.id,
      },
      {
        header: "Cliente",
        id: "clientName",
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].tariff.contract.client.fullName,
      },
      {
        header: "Categoria Produto",
        id: "productCategoryName",
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].tariff.contract.product.category,
      },
      {
        header: "Produto",
        id: "productName",
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].tariff.contract.product.name,
      },

      {
        header: "Projeto",
        id: "projectId",
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].tariff.idProject,
      },
      {
        header: "Contrato",
        accessorKey: "contract",
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].tariff.contract.idContract,
      },
      {
        header: "Canal",
        accessorKey: "channel",
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].tariff.contract.channel.description,
      },
      {
        header: "Prestador",
        accessorKey: "provider",
        accessorFn: (row: IBoletoList) =>
          row.charge.tariffCars[0].tariff.contract.channel.provider.name,
      },
      {
        header: "Vencimento",
        accessorFn: (row: IBoletoList) => row.boletoJson?.dataVencimento,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => {
          if (!info.getValue()) return null;
          return <DueDateCell dueDate={info.getValue()} />;
        },
      },
      {
        header: "Valor",
        accessorFn: (row: IBoletoList) => row.boletoJson?.valorDocumento,

        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => {
          if (!info.getValue()) return null;
          return Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        header: "PDF Gerado?",
        accessorKey: "blobName",
        enableSorting: false,
        cell: (info) => (!!info.getValue() ? "Sim" : "Não"),
      },
      {
        header: "Ações",
        id: "actions",
        cell: (info) => <BoletoPopover info={info} />,
      },
    ],
    []
  );
  useEffect(() => {
    const filters = {
      ...columnFiltersParams,
      invoiceId: columnFiltersParams?.invoiceId ?? invoiceId,
      tariffId: columnFiltersParams?.tariffId ?? tariffId,
    };
    handleSearch(undefined, paginationParams, filters, columnSortingParams);
  }, [
    columnFiltersParams,
    columnSortingParams,
    invoiceId,
    paginationParams,
    query,
  ]);

  const breadcrumb: IBreadcrumbProps[] = [
    {
      description: "Boletos",
      href: "/boletos",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600">Boletos</HLarge2xl>
      <BoletosFilters handleSearch={handleSearch} />
      <Flex justifyContent="flex-end" alignItems="center" mb="1rem" />
      <TableServer
        refresh={() => handleSearch()}
        data={boletos.data}
        defaultColumns={columns}
        isLoading={isLoading}
        columnFiltersState={columnFiltersState}
        paginationState={paginationState}
        sortingState={sortingState}
      />
      <StatusSubtitle />
    </Wrapper>
  );
};
