import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { TSmallmd } from "../../../../../main/components/Tipography/TextSmall";
import { useGetBanksBrasilApi } from "../../../../../main/hooks/useGetBanksBrasilApi";
import {
  enumTypeOfPayment,
  IContractsData,
} from "../../../../../types/main/contract";
import { useContract } from "../../hooks/useContract";
import { useDataContracts } from "../../hooks/useDataContracts";
import { AutomaticDebit } from "./Tabs/AutomaticDebit";
import { Boleto } from "./Tabs/Boleto";
import { PrePaid } from "./Tabs/PrePaid";
import { ThirdPartyDebit } from "./Tabs/ThirdPartyDebit";

interface IContractForm {
  contractUUID?: string;
  isReadOnly?: boolean;
}

export type IContractsDataModified = Omit<
  IContractsData,
  "juros" | "multa" | "monthlyInterest"
> & {
  juros: string;
  multa: string;
  monthlyInterest: string;
};

export const ContractForm: React.FC<IContractForm> = ({
  contractUUID,
  isReadOnly,
}) => {
  const { allBanksList } = useGetBanksBrasilApi();

  const {
    existingContractData,
    allClientsList,
    allProductsList,
    allChannelsList,
  } = useDataContracts(contractUUID);

  const [showPaymentMethod, setShowPaymentMethod] = useState({
    boleto: true,
    automaticDebit: true,
    thirdPartyDebit: true,
    prePaid: true,
  });

  const { isLoading, onSubmitToCreateContract, onSubmitToUpdateContract } =
    useContract(contractUUID);

  useEffect(() => {
    if (contractUUID) {
      if (
        existingContractData &&
        existingContractData.typeOfPayment === "AUTOMATIC_DEBIT"
      ) {
        setShowPaymentMethod({
          automaticDebit: true,
          boleto: false,
          thirdPartyDebit: false,
          prePaid: false,
        });
      } else if (
        existingContractData &&
        existingContractData.typeOfPayment === "THIRD_PARTY_DEBIT"
      ) {
        setShowPaymentMethod({
          automaticDebit: false,
          boleto: false,
          thirdPartyDebit: true,
          prePaid: false,
        });
      } else if (
        existingContractData &&
        existingContractData.typeOfPayment === "BOLETO"
      ) {
        setShowPaymentMethod({
          automaticDebit: false,
          boleto: true,
          thirdPartyDebit: false,
          prePaid: false,
        });
      } else if (
        existingContractData &&
        existingContractData.typeOfPayment === "PRE_PAID"
      ) {
        setShowPaymentMethod({
          automaticDebit: false,
          boleto: false,
          thirdPartyDebit: false,
          prePaid: true,
        });
      }
    }
  }, [contractUUID, existingContractData]);

  return (
    <>
      <Tabs variant="unstyled">
        <TabList>
          {showPaymentMethod.automaticDebit && (
            <Tab
              _selected={{
                borderBottomColor: "blue.500",
                borderBottomWidth: "2px",
                color: "blue.600",
              }}
            >
              <TSmallmd>{enumTypeOfPayment.AUTOMATIC_DEBIT}</TSmallmd>
            </Tab>
          )}
          {showPaymentMethod.boleto && (
            <Tab
              _selected={{
                borderBottomColor: "blue.500",
                borderBottomWidth: "2px",
                color: "blue.600",
              }}
            >
              <TSmallmd>{enumTypeOfPayment.BOLETO}</TSmallmd>
            </Tab>
          )}
          {showPaymentMethod.thirdPartyDebit && (
            <Tab
              _selected={{
                borderBottomColor: "blue.500",
                borderBottomWidth: "2px",
                color: "blue.600",
              }}
            >
              <TSmallmd> {enumTypeOfPayment.THIRD_PARTY_DEBIT}</TSmallmd>
            </Tab>
          )}
          {showPaymentMethod.prePaid && (
            <Tab
              _selected={{
                borderBottomColor: "blue.500",
                borderBottomWidth: "2px",
                color: "blue.600",
              }}
            >
              <TSmallmd>{enumTypeOfPayment.PRE_PAID}</TSmallmd>
            </Tab>
          )}
        </TabList>

        <TabPanels>
          {showPaymentMethod.automaticDebit && (
            <TabPanel>
              <AutomaticDebit
                onSubmitToCreateContract={onSubmitToCreateContract}
                onSubmitToUpdateContract={onSubmitToUpdateContract}
                contractUUID={contractUUID}
                isReadOnly={isReadOnly}
                existingContractData={existingContractData}
                allClientsList={allClientsList}
                allProductsList={allProductsList}
                allBanksList={allBanksList}
                allChannelsList={allChannelsList}
              />
            </TabPanel>
          )}
          {showPaymentMethod.boleto && (
            <TabPanel>
              <Boleto
                onSubmitToCreateContract={onSubmitToCreateContract}
                onSubmitToUpdateContract={onSubmitToUpdateContract}
                contractUUID={contractUUID}
                isReadOnly={isReadOnly}
                existingContractData={existingContractData}
                allClientsList={allClientsList}
                allProductsList={allProductsList}
                allChannelsList={allChannelsList}
              />
            </TabPanel>
          )}
          {showPaymentMethod.thirdPartyDebit && (
            <TabPanel>
              <ThirdPartyDebit
                onSubmitToCreateContract={onSubmitToCreateContract}
                onSubmitToUpdateContract={onSubmitToUpdateContract}
                contractUUID={contractUUID}
                isReadOnly={isReadOnly}
                existingContractData={existingContractData}
                allClientsList={allClientsList}
                allProductsList={allProductsList}
                allChannelsList={allChannelsList}
                allBanksList={allBanksList}
              />
            </TabPanel>
          )}
          {showPaymentMethod.prePaid && (
            <TabPanel>
              <PrePaid
                onSubmitToCreateContract={onSubmitToCreateContract}
                onSubmitToUpdateContract={onSubmitToUpdateContract}
                contractUUID={contractUUID}
                isReadOnly={isReadOnly}
                existingContractData={existingContractData}
                allClientsList={allClientsList}
                allProductsList={allProductsList}
                allChannelsList={allChannelsList}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      {isLoading && <Backdrop />}
    </>
  );
};
