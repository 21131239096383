import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import { IContractsDataModified } from "..";
import {
  DEFAULT_REGEX_ONLY_NUMBERS_WITH_THREE_DECIMALS,
  DEFAULT_REGEX_ONLY_NUMBERS_WITH_TWO_DECIMALS,
} from "../../../../../../main/common/constants/defaultValidations";
import { customToast } from "../../../../../../main/common/utils/customToast";
import { format_CPF_CNPJ } from "../../../../../../main/common/utils/format_CPF_CNPJ";
import { DatePicker } from "../../../../../../main/components/DateTimePicker/DatePicker";
import { InputSelect } from "../../../../../../main/components/InputSelect";
import { InputSelectAsync } from "../../../../../../main/components/InputSelectAsync";
import { NumberFilterInput } from "../../../../../../main/components/NumberFilterInput";
import { IBrasilAPIBanks } from "../../../../../../main/services/BrasilAPIService";
import { IClientOptions } from "../../../../../../types/main/client";
import {
  IContractsData,
  IContractsFields,
} from "../../../../../../types/main/contract";
import { ClientModal } from "../../../../Clients/components/ClientModal";
import { getClientOptions } from "../../../../Clients/service/ClientService";
import { IUseGetAllProduct } from "../../../../shared/hooks/Products/useGetAllProducts";
import { convertHookFormTypeToAPItype } from "../../../functions/convertion";
import { boletoSchema } from "../../../validations/boletoSchema";
import { ButtonSubmit } from "../ButtonSubmit";

interface IProps {
  isReadOnly?: boolean;
  contractUUID?: string;
  onSubmitToCreateContract: (
    data: IContractsData,
    handleReset: () => void
  ) => Promise<void>;
  onSubmitToUpdateContract: (data: IContractsData) => Promise<void>;
  existingContractData?: IContractsFields;
  allClientsList?: Array<IClientOptions>;
  allProductsList?: Array<IUseGetAllProduct>;
  allBanksList?: Array<IBrasilAPIBanks>;
  allChannelsList?: Array<{ name: string; uuid: string; deletedAt?: Date }>;
}

export const Boleto = ({
  contractUUID,
  isReadOnly,
  onSubmitToCreateContract,
  onSubmitToUpdateContract,
  existingContractData,
  allClientsList,
  allProductsList,
  allChannelsList,
}: IProps) => {
  const methods = useForm<IContractsDataModified>({
    resolver: yupResolver(boletoSchema),
  });

  const [clientDocument, setClientDocument] = useState<string>("");

  const onClientChange = (value: IClientOptions) => {
    setClientDocument(value.document);
  };

  useEffect(() => {
    if (existingContractData) {
      methods.reset({
        channelId: existingContractData.channelId,
        clientUuid: existingContractData.clientUuid,
        idContract: existingContractData.idContract,
        inclusionDate: existingContractData.inclusionDate,
        productUuid: existingContractData.productUuid,
        registrationNumber: existingContractData.registrationNumber,
        juros: Number(existingContractData.juros).toFixed(3),
        multa: Number(existingContractData.multa).toFixed(2),
        monthlyInterest: Number(existingContractData.monthlyInterest).toFixed(
          3
        ),
      });

      setClientDocument(existingContractData.client.document);
    }
    methods.setValue("typeOfPayment", "BOLETO");
  }, [existingContractData, methods]);

  const watchClientUuid = methods.watch("clientUuid");

  const chooseSubmit = (data: IContractsDataModified) => {
    const validateDeletedAtChannel = allChannelsList?.find(
      ({ uuid, deletedAt }) =>
        Number(uuid) === Number(data.channelId) && deletedAt
    );

    const validateDeletedAtProduct = allProductsList?.find(
      ({ uuid, deletedAt }) => uuid === data.productUuid && deletedAt
    );

    const validateDeletedAtClient = allClientsList?.find(
      ({ uuid, deletedAt }) => uuid === data.clientUuid && deletedAt
    );

    if (validateDeletedAtChannel) {
      customToast(
        "error",
        `${validateDeletedAtChannel.name} está inativo, selecione outro canal ou contate o administrador`
      );
    } else if (validateDeletedAtProduct) {
      customToast(
        "error",
        `${validateDeletedAtProduct.name} está inativo, selecione outro produto ou contate o administrador`
      );
    } else if (validateDeletedAtClient) {
      customToast(
        "error",
        `${validateDeletedAtClient.fullName} está inativo, selecione outro cliente ou contate o administrador`
      );
    } else {
      const convertedData = convertHookFormTypeToAPItype(data);
      if (contractUUID) {
        onSubmitToUpdateContract(convertedData);
        return;
      }

      onSubmitToCreateContract(convertedData, methods.reset);
    }
  };

  return (
    <FormProvider {...methods}>
      <Flex
        mt="32px"
        as="form"
        onSubmit={methods.handleSubmit(chooseSubmit)}
        h="100%"
        flexDirection="column"
        justifyContent="space-between"
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Grid
          h="300px"
          templateRows="(4, 1fr)"
          templateColumns="repeat(12, 1fr)"
          gap={8}
        >
          <GridItem colSpan={3}>
            <FormControl isInvalid={!!methods.formState.errors?.idContract}>
              <FormLabel htmlFor="idContract">ID do contrato</FormLabel>
              <NumberFilterInput
                fieldname="idContract"
                disabled={isReadOnly}
                type="number"
              />

              <FormErrorMessage>
                {methods.formState.errors.idContract &&
                  methods.formState.errors.idContract.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isInvalid={!!methods.formState.errors.channelId}>
              <FormLabel htmlFor="channel">Canal</FormLabel>
              <InputSelect
                disabled={isReadOnly}
                name="channelId"
                option={
                  allChannelsList?.map(({ uuid, name, deletedAt }) => {
                    return {
                      label: deletedAt ? `(inativo) ${name}` : name,
                      value: uuid,
                      deletedAt,
                    };
                  }) ?? []
                }
              />
              <FormErrorMessage>
                {methods.formState.errors.channelId &&
                  methods.formState.errors.channelId.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isInvalid={!!methods.formState.errors.clientUuid}>
              <FormLabel htmlFor="client">Cliente</FormLabel>
              <Flex gap="8px">
                {allClientsList && (
                  <InputSelectAsync
                    fetchOptions={getClientOptions}
                    disabled={isReadOnly}
                    name="clientUuid"
                    placeholder="Texto"
                    onChange={(option) =>
                      onClientChange(option as IClientOptions)
                    }
                    option={
                      allClientsList.map((option) => {
                        return {
                          ...option,
                          label: option.deletedAt
                            ? `(inativo) ${option.fullName}`
                            : option.fullName,
                          value: option.uuid,
                        };
                      }) ?? []
                    }
                  />
                )}
                {watchClientUuid && <ClientModal uuid={watchClientUuid} />}
              </Flex>
              <FormErrorMessage>
                {!!methods.formState.errors.clientUuid &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormLabel>Documento cliente</FormLabel>
            <Input isReadOnly value={format_CPF_CNPJ(clientDocument)} />
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isInvalid={!!methods.formState.errors.productUuid}>
              <FormLabel htmlFor="product">Produto</FormLabel>
              <InputSelect
                disabled={isReadOnly}
                name="productUuid"
                option={
                  allProductsList?.map(({ uuid, name, deletedAt }) => {
                    return {
                      label: deletedAt ? `(inativo) ${name}` : name,
                      value: uuid,
                      deletedAt,
                    };
                  }) ?? []
                }
              />
              <FormErrorMessage>
                {!!methods.formState.errors.productUuid &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl isInvalid={!!methods.formState.errors.inclusionDate}>
              <FormLabel htmlFor="inclusionDate">
                Data de criação do contrato
              </FormLabel>
              <DatePicker fieldname="inclusionDate" isDisabled={isReadOnly} />
              <FormErrorMessage>
                {methods.formState.errors.inclusionDate &&
                  methods.formState.errors.inclusionDate.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl isInvalid={!!methods.formState.errors.juros}>
              <FormLabel htmlFor="juros">Taxa de juros diário</FormLabel>
              <NumberFilterInput
                isReadOnly={isReadOnly}
                fieldname="juros"
                type="percentage"
                size="md"
                regex={DEFAULT_REGEX_ONLY_NUMBERS_WITH_THREE_DECIMALS}
                onChange={(e) => {
                  return e.replaceAll(",", ".");
                }}
              />
              <FormErrorMessage>
                {methods.formState.errors.juros &&
                  methods.formState.errors.juros.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl isInvalid={!!methods.formState.errors.monthlyInterest}>
              <FormLabel htmlFor="monthlyInterest">
                Taxa de juros mensal
              </FormLabel>
              <NumberFilterInput
                isReadOnly={isReadOnly}
                fieldname="monthlyInterest"
                type="percentage"
                size="md"
                regex={DEFAULT_REGEX_ONLY_NUMBERS_WITH_THREE_DECIMALS}
                onChange={(e) => {
                  return e.replaceAll(",", ".");
                }}
              />
              <FormErrorMessage>
                {methods.formState.errors.monthlyInterest &&
                  methods.formState.errors.monthlyInterest.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl isInvalid={!!methods.formState.errors.multa}>
              <FormLabel htmlFor="multa">Multa</FormLabel>
              <NumberFilterInput
                isReadOnly={isReadOnly}
                fieldname="multa"
                type="percentage"
                size="md"
                regex={DEFAULT_REGEX_ONLY_NUMBERS_WITH_TWO_DECIMALS}
                onChange={(e) => {
                  return e.replaceAll(",", ".");
                }}
              />
              <FormErrorMessage>
                {methods.formState.errors.multa &&
                  methods.formState.errors.multa.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl
              isInvalid={!!methods.formState.errors.registrationNumber}
            >
              <FormLabel htmlFor="registrationNumber">Nº em Cartório</FormLabel>
              <Input
                id="registrationNumber"
                placeholder="Texto"
                {...methods.register("registrationNumber")}
                isReadOnly={isReadOnly}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.registrationNumber &&
                  methods.formState.errors.registrationNumber.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={6}>
            {existingContractData?.idContract && (
              <Box
                as="a"
                href={`https://libertas.interstar.net.br/contracts/${existingContractData?.idContract}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  rightIcon={<BsFillArrowUpRightCircleFill />}
                  variant="outline"
                >{`https://libertas.interstar.net.br/contracts/${existingContractData?.idContract}`}</Button>
              </Box>
            )}
          </GridItem>

          <GridItem colSpan={6} pb="32px">
            <ButtonSubmit
              contractUUID={contractUUID}
              isDisabled={isReadOnly}
              isSubmitting={methods.formState.isSubmitting}
            />
          </GridItem>
        </Grid>
      </Flex>
    </FormProvider>
  );
};
