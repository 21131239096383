import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { Backdrop } from "../../../../main/components/Backdrop";
import { ConfirmationPopover } from "../../../../main/components/ConfirmationPopover";
import { useAuth } from "../../../../main/hooks/useAuth";
import { CAN_PERMISSION_EDIT } from "../constants/permissions";
import {
  IUndoManualWriteOff,
  IUndoWriteOffForm,
} from "../interfaces/undo-manual-write-off.interface";
import { undoManualWriteOffSchema } from "../schemas/undo-manual-write-off";
import { undoWriteOffReceivable } from "../service/undo-write-off.service";

export const UndoManualWriteOff = ({
  uuid,
  isOpen,
  onClose,
  refetch,
  id,
}: IUndoManualWriteOff) => {
  const [isLoading, setIsLoading] = useState(false);
  const { permissionLevel } = useAuth();
  const toast = useToast();

  const methods = useForm<IUndoWriteOffForm>({
    resolver: yupResolver(undoManualWriteOffSchema),
  });

  if (!CAN_PERMISSION_EDIT[permissionLevel] || !uuid) {
    return null;
  }

  const processingStatusCarUuid = methods.watch("processingStatusCarUuid");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const data = methods.getValues();
      await undoWriteOffReceivable(data, uuid);
      toast({
        position: "bottom",
        title: "Sucesso",
        description: "Ação realizada com sucesso",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      await refetch();
    } catch (error: any) {
      toast({
        position: "bottom",
        title: "Ocorreu um erro",
        description: error?.message ?? DEFAULT_ERROR_MESSAGES.DEFAULT_ERROR,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Error on submit", error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Desfazer baixa manual do CAR {id}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={onSubmit}>
              <FormControl>
                <FormLabel htmlFor="processingStatusCarUuid">
                  Defina o status do CAR
                </FormLabel>
                <Select
                  name="processingStatusCarUuid"
                  placeholder="Qual o status o CAR deve receber?"
                  onChange={(e) => {
                    methods.setValue(
                      "processingStatusCarUuid",
                      +e.target.value
                    );
                  }}
                >
                  <option value={1}>Aguardando envio</option>
                  <option value={3}>Débito em aberto</option>
                </Select>
              </FormControl>
            </form>
            {isLoading && <Backdrop />}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Cancelar
            </Button>
            <ConfirmationPopover
              disabled={!processingStatusCarUuid}
              triggerButtonText="Enviar"
              onPrimaryButtonClick={onSubmit}
              title="Confirmação"
              text={`Tem certeza que deseja desfazer a baixa manual do CAR ${id}?`}
              primaryButtonText="Sim"
              secondaryButtonText="Não"
            />
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};
